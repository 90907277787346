<template>
    <div id="web_bg">
        <div class="login-main">
            <div class="login-left">
                <div class="login-left-txt">
                    欢迎登录仓库管理系统
                </div>
            </div>
            <div class="login-right">
                <div class="login-right-all">
                    <div class="login-right-txt">登录</div>
                    <div>
                        <div class="login-user-all login-form">
                            <img src="../../assets/image/user.png" />
                            <input v-model="ruleForm.admin_name" placeholder="请输入账号" />
                        </div>
                        <div class="login-pwd-all login-form">
                            <img src="../../assets/image/pwd.png" />
                            <input type="password" v-model="ruleForm.password"  @keyup.enter="loginClick" placeholder="请输入密码" />
                        </div>
                    </div>
                    <el-button round class="logon-button" :loading="loginStatus" @click="loginClick">登录</el-button>
                </div>
            </div>
        </div>

        <div class="beian">
            Copyright © 手表进销存管理系统 2020-2020 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021001884号-1</a>
        </div>
        
        
        
    </div>
</template>
<script>
import {left_list,login,banner} from '@/api/login.js'
export default {
    name:'Login',
    data(){
        return {
            ruleForm: {
                admin_name: '',
                password: '',
            },
            loginStatus:false,

        };
        
    },
    created(){
        // ceshi().then(res=>{

        // })
    },
    
    methods:{
        loginClick(){
            if(this.ruleForm.admin_name == '' || this.ruleForm.password == ''){
                this.$message.error('请输入填写完整信息');
                return
            }
            this.loginStatus = true
            login(this.ruleForm).then(res=>{
                if(res.code == 200){
                    sessionStorage.setItem('user_info', JSON.stringify(res.data));
                    sessionStorage.setItem('token', res.data.token);
                    this.$store.commit('SET_USERINFO', res.data);
                    this.loginStatus = false
                    this.getList();
                }else{
                    this.loginStatus = false
                }
            })
        },
        // 获取菜单 生成动态路由
        getList(){
            left_list().then(res=>{
                console.log(res)
                this.$store.commit('SET_ROUTES', res.data);
                this.$router.push(res.data[0].path)
                this.open1()

            })
        },

        // 发起通知
        open1() {
            const h = this.$createElement;
            this.$notify({
            title: '登录成功',
            message: h('i', { style: 'color: teal'}, '你好,' + this.$store.state.userInfo.nickname + '欢迎回来')
            });
        },
    }
}
</script>
<style scoped>
::-webkit-input-placeholder { color:#808080; }
::-moz-placeholder { color:#808080; } /* firefox 19+ */
:-ms-input-placeholder { color:#808080; } /* ie */
input:-moz-placeholder { color:#808080; }
input:focus{
	outline: medium;
}
#web_bg{
  position:fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index:-10;
  zoom: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/image/login-bg.png');
  /* background-size: 100% 100%; */
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.login-main{
    display: flex;
    background-image: url('../../assets/image/login-main.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1100px;
    height: 560px;
    z-index: 1;
}
.login-left{
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 34px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;

}
.login-left-txt{
    margin-left: 60px;
}
.login-right{
    flex: 1;
    
}
.login-right-all{
    padding: 72px 30px 0 30px;
}
.login-right-txt{
    font-size: 40px;
    color: #4084F2;
    line-height: 98px;

}
.login-form{
    display: flex;
    align-items: center;
    padding: 22px 10px;
    border-bottom: 1px solid #F1F1F1;
    margin-top: 40px;

}
.login-form:nth-child(1){
    margin-top: 0;
}
.login-form input{
    font-size: 28px;
    border: none;
}
.login-user-all{

}
.login-user-all img{
    width: 35px;
    height: 40px;
    margin-right: 30px;
}
.login-pwd-all{

}
.login-pwd-all img{
    width: 30px;
    height: 38px;
    margin-right: 30px;
}
.logon-button{
    margin-top: 72px;
    width: 100%;
    color: #FEFEFE;
    height: 80px;
    font-size: 30px;
    background: linear-gradient(270deg, #407FF2, #4CC1FF);
    border-radius: 40px;
    border: none;
}
.beian{
    position: fixed;
    bottom: 40px;
    font-size: 20px;
    color: #fff;
}
.beian a{
    color: #ffffff;
}
</style>