import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'

/**
 * 获取路由
 */
export function left_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/auth/rule/rule/left_list',
        method: 'get',
        data
    })
}

/**
 * 登录
 */
export function login(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/login/index',
        method: 'post',
        data
    })
}



// /**
//  * 出货单详情
//  */
// export function ceshi(data={}) {
//     return service.request({
//       url: '/api/v1/banner',
//       method: 'get',
//       data
//     })
// }
  